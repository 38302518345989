var Webflow = require('../BaseSiteModules/webflow-lib');
var lottieSiteModule = require('./modules/LottieSiteModule');
var lottie = require('lottie-web/build/player/lottie.min');

Webflow.define(
  'lottie',
  (module.exports = function () {
    return {
      lottie,
      createInstance: lottieSiteModule.createInstance,
      cleanupElement: lottieSiteModule.cleanupElement,
      init: lottieSiteModule.init,
      destroy: lottieSiteModule.destroy,
      ready: lottieSiteModule.ready,
    };
  })
);
