/* eslint-env browser */

import findIndex from 'lodash/findIndex';
import 'core-js/features/array';

import type {
  LottieItem,
  OnFrameChangeCallback,
  OnLoopCompleteCallback,
} from './types';

import {fetchLottie} from '@packages/systems/core/utils/LottieFetchUtils';

const getLottieLibrary = (win: Window) => win.Webflow.require('lottie').lottie;

const isInDesigner = (win: Window) =>
  Boolean(win.Webflow.env('design') || win.Webflow.env('preview'));

const PlayerState = {
  Playing: 'playing' as const,
  Stopped: 'stopped' as const,
} as const;

type LoadAnimation = {
  src: string;
  loop: boolean;
  autoplay: boolean;
  renderer: 'svg' | 'canvas';
  direction: 1 | -1;
  duration: number;
  hasIx2: boolean;
  ix2InitialValue: null | number;
  preserveAspectRatio: string;
};

class Cache {
  _cache: Array<{
    wrapper: HTMLElement;
    instance: LottieInstance;
  }> = [];

  set(container: HTMLElement, instance: LottieInstance): void {
    const index = findIndex(this._cache, ({wrapper}) => wrapper === container);
    if (index !== -1) this._cache.splice(index, 1);
    this._cache.push({wrapper: container, instance});
  }

  delete(container: HTMLElement): void {
    const index = findIndex(this._cache, ({wrapper}) => wrapper === container);
    if (index !== -1) this._cache.splice(index, 1);
  }

  get(container: HTMLElement): LottieInstance | null {
    const index = findIndex(this._cache, ({wrapper}) => wrapper === container);
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    return index !== -1 ? this._cache[index].instance : null;
  }
}

const cache = new Cache();
const emptyObject: Record<string, any> = {};

class LottieInstance implements LottieItem {
  config: null | LoadAnimation = null;
  declare container: null | HTMLElement;
  currentState: (typeof PlayerState)[keyof typeof PlayerState] =
    PlayerState.Stopped;
  animationItem: any;

  handlers: {
    enterFrame: Array<OnFrameChangeCallback>;
    complete: Array<() => void>;
    loop: Array<OnLoopCompleteCallback>;
    dataReady: Array<() => void>;
    destroy: Array<() => void>;
    error: Array<(arg1: Error) => void>;
  } = {
    enterFrame: [],
    complete: [],
    loop: [],
    dataReady: [],
    destroy: [],
    error: [],
  };

  load(container: HTMLElement): void {
    const dataset = container.dataset || emptyObject;
    const src = dataset.src || '';

    if (src.endsWith('.lottie')) {
      fetchLottie(src).then((animationData) => {
        this._loadAnimation(container, animationData);
      });
    } else {
      this._loadAnimation(container, undefined);
    }
    cache.set(container, this);
    this.container = container;
  }

  _loadAnimation(
    container: HTMLElement,
    animationData?: Record<any, any>
  ): void {
    const dataset = container.dataset || emptyObject;
    const src = dataset.src || '';
    // Available options here https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
    const preserveAspectRatio = dataset.preserveAspectRatio || 'xMidYMid meet';
    const renderer = dataset.renderer || ('svg' as 'svg' | 'canvas');
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const loop = parseFloat(dataset.loop) === 1;
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const direction = parseFloat(dataset.direction) || (1 as 1 | -1);
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const autoplay = parseFloat(dataset.autoplay) === 1;
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const duration = parseFloat(dataset.duration) || 0;
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    const hasIx2 = parseFloat(dataset.isIx2Target) === 1;
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    let ix2InitialValue = parseFloat(dataset.ix2InitialState);

    if (isNaN(ix2InitialValue)) {
      // @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'number'.
      ix2InitialValue = null;
    }

    const config = {
      src,
      loop,
      autoplay,
      renderer,
      direction,
      duration,
      hasIx2,
      ix2InitialValue,
      preserveAspectRatio,
    } as const;

    // If it's the same path/src, don't destroy the animation
    if (
      this.animationItem &&
      this.config &&
      this.config.src === src &&
      renderer === this.config.renderer &&
      preserveAspectRatio === this.config.preserveAspectRatio
    ) {
      if (loop !== this.config.loop) {
        this.setLooping(loop);
      }

      if (!hasIx2) {
        if (direction !== this.config.direction) {
          // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type '1 | -1'.
          this.setDirection(direction);
        }

        if (duration !== this.config.duration) {
          if (duration > 0 && duration !== this.duration) {
            this.setSpeed(this.duration / duration);
          } else {
            this.setSpeed(1);
          }
        }
      }

      if (autoplay) {
        this.play();
      }

      if (ix2InitialValue && ix2InitialValue !== this.config.ix2InitialValue) {
        const percent = ix2InitialValue / 100;
        this.goToFrame(this.frames * percent);
      }

      // @ts-expect-error - TS2322 - Type '{ readonly src: string; readonly loop: boolean; readonly autoplay: boolean; readonly renderer: string; readonly direction: number; readonly duration: number; readonly hasIx2: boolean; readonly ix2InitialValue: number; readonly preserveAspectRatio: string; }' is not assignable to type 'LoadAnimation'.
      this.config = config;
      return;
    }

    const options = {
      container,
      loop,
      autoplay,
      renderer,
      rendererSettings: {
        preserveAspectRatio,
        progressiveLoad: true,
        hideOnTransparent: true,
      },
    } as const;
    const win = container.ownerDocument.defaultView as Window;
    try {
      // Clear previous animation, if any
      if (this.animationItem) {
        this.destroy();
      }

      // Initialize lottie player and load animation
      this.animationItem = getLottieLibrary(win).loadAnimation({
        ...options,
        ...(animationData ? {animationData} : {path: src}),
      });
    } catch (err: any) {
      this.handlers.error.forEach((cb) => cb(err));
      return;
    }

    if (!this.animationItem) return;

    if (isInDesigner(win)) {
      // Calculate and save the current progress of the animation
      this.animationItem.addEventListener('enterFrame', () => {
        if (!this.isPlaying) return;

        const {currentFrame, totalFrames, playDirection} = this.animationItem;
        const toPercent = (currentFrame / totalFrames) * 100;
        const percentage = Math.round(
          playDirection === 1 ? toPercent : 100 - toPercent
        );

        this.handlers.enterFrame.forEach((cb) => cb(percentage, currentFrame));
      });

      // Handle animation play complete
      this.animationItem.addEventListener('complete', () => {
        if (this.currentState !== PlayerState.Playing) {
          this.handlers.complete.forEach((cb) => cb());
          return;
        }

        if (!this.animationItem.loop) {
          this.handlers.complete.forEach((cb) => cb());
          return;
        }
        this.currentState = PlayerState.Stopped;
      });

      // Handle animation play complete
      this.animationItem.addEventListener(
        'loopComplete',
        (loopComplete: {currentLoop: number; totalLoops: number | boolean}) => {
          this.handlers.loop.forEach((cb) => cb(loopComplete));
        }
      );

      // Set error state when animation load fail event triggers
      // @ts-expect-error - TS7006 - Parameter 'err' implicitly has an 'any' type.
      this.animationItem.addEventListener('data_failed', (err) => {
        this.handlers.error.forEach((cb) => cb(err));
      });

      // Set error state when animation load fail event triggers
      // @ts-expect-error - TS7006 - Parameter 'err' implicitly has an 'any' type.
      this.animationItem.addEventListener('error', (err) => {
        this.handlers.error.forEach((cb) => cb(err));
      });
    }

    if (this.isLoaded) {
      this.handlers.dataReady.forEach((cb) => cb());

      if (autoplay) {
        this.play();
      }
    } else {
      // Handle animation data load complete
      this.animationItem.addEventListener('data_ready', () => {
        this.handlers.dataReady.forEach((cb) => cb());

        // Only set the direction and speed if no IX2 is attached
        if (!hasIx2) {
          // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type '1 | -1'.
          this.setDirection(direction);

          if (duration > 0 && duration !== this.duration) {
            this.setSpeed(this.duration / duration);
          }

          if (autoplay) {
            this.play();
          }
        }

        // Set the animation's initial state value from IX2
        if (ix2InitialValue) {
          const percent = ix2InitialValue / 100;
          this.goToFrame(this.frames * percent);
        }
      });
    }

    // @ts-expect-error - TS2322 - Type '{ readonly src: string; readonly loop: boolean; readonly autoplay: boolean; readonly renderer: string; readonly direction: number; readonly duration: number; readonly hasIx2: boolean; readonly ix2InitialValue: number; readonly preserveAspectRatio: string; }' is not assignable to type 'LoadAnimation'.
    this.config = config;
  }

  onFrameChange(cb: OnFrameChangeCallback) {
    if (this.handlers.enterFrame.indexOf(cb) === -1) {
      this.handlers.enterFrame.push(cb);
    }
  }

  onPlaybackComplete(cb: () => void) {
    if (this.handlers.complete.indexOf(cb) === -1) {
      this.handlers.complete.push(cb);
    }
  }

  onLoopComplete(cb: OnLoopCompleteCallback) {
    if (this.handlers.loop.indexOf(cb) === -1) {
      this.handlers.loop.push(cb);
    }
  }

  onDestroy(cb: () => void) {
    if (this.handlers.destroy.indexOf(cb) === -1) {
      this.handlers.destroy.push(cb);
    }
  }

  onDataReady(cb: () => void) {
    if (this.handlers.dataReady.indexOf(cb) === -1) {
      this.handlers.dataReady.push(cb);
    }
  }

  onError(cb: () => void) {
    if (this.handlers.error.indexOf(cb) === -1) {
      this.handlers.error.push(cb);
    }
  }

  play() {
    if (!this.animationItem) return;
    const frame = this.animationItem.playDirection === 1 ? 0 : this.frames;
    this.animationItem.goToAndPlay(frame, true);
    this.currentState = PlayerState.Playing;
  }

  stop(): void {
    if (!this.animationItem) return;

    if (this.isPlaying) {
      const {playDirection} = this.animationItem;
      const frame = playDirection === 1 ? 0 : this.frames;
      this.animationItem.goToAndStop(frame, true);
    }

    this.currentState = PlayerState.Stopped;
  }

  destroy(): void {
    if (!this.animationItem) return;

    if (this.isPlaying) this.stop();
    this.handlers.destroy.forEach((cb) => cb());

    if (this.container) {
      cache.delete(this.container);
    }

    this.animationItem.destroy();
    Object.keys(this.handlers).forEach(
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ enterFrame: OnFrameChangeCallback[]; complete: (() => void)[]; loop: OnLoopCompleteCallback[]; dataReady: (() => void)[]; destroy: (() => void)[]; error: ((arg1: Error) => void)[]; }'.
      (key) => (this.handlers[key].length = 0)
    );
    this.animationItem = null;
    this.container = null;
    this.config = null;
  }

  // @ts-expect-error - TS2416 - Property 'isPlaying' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get isPlaying(): boolean {
    if (!this.animationItem) return false;
    return !this.animationItem.isPaused;
  }

  // @ts-expect-error - TS2416 - Property 'isPaused' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get isPaused(): boolean {
    if (!this.animationItem) return false;
    return this.animationItem.isPaused;
  }

  // @ts-expect-error - TS2416 - Property 'duration' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get duration(): number {
    if (!this.animationItem) return 0;
    return this.animationItem.getDuration();
  }

  // @ts-expect-error - TS2416 - Property 'frames' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get frames(): number {
    if (!this.animationItem) return 0;
    return this.animationItem.totalFrames;
  }

  // @ts-expect-error - TS2416 - Property 'direction' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get direction(): 1 | -1 {
    if (!this.animationItem) return 1;
    return this.animationItem.playDirection;
  }

  // @ts-expect-error - TS2416 - Property 'isLoaded' in type 'LottieInstance' is not assignable to the same property in base type 'LottieItem'.
  get isLoaded(): boolean {
    if (!this.animationItem) false;
    return this.animationItem.isLoaded;
  }

  get ix2InitialValue(): number | null {
    return this.config ? this.config.ix2InitialValue : null;
  }

  goToFrame(value: number) {
    if (!this.animationItem) return;
    this.animationItem.setCurrentRawFrameValue(value);
  }

  setSubframe(value: boolean) {
    if (!this.animationItem) return;
    this.animationItem.setSubframe(value);
  }

  setSpeed(value: number = 1): void {
    if (!this.animationItem) return;
    if (this.isPlaying) this.stop();
    this.animationItem.setSpeed(value);
  }

  setLooping(value: boolean): void {
    if (!this.animationItem) return;
    if (this.isPlaying) this.stop();
    this.animationItem.loop = value;
  }

  setDirection(value: 1 | -1): void {
    if (!this.animationItem) return;
    if (this.isPlaying) this.stop();
    this.animationItem.setDirection(value);
    this.goToFrame(value === 1 ? 0 : this.frames);
  }
}

const getLottieElements = (): Array<HTMLElement> =>
  Array.from(document.querySelectorAll('[data-animation-type="lottie"]'));

export const createInstance = (container: HTMLElement) => {
  let lottieInstance = cache.get(container);

  if (lottieInstance == null) {
    lottieInstance = new LottieInstance();
  }

  lottieInstance.load(container);

  return lottieInstance;
};

export const cleanupElement = (element: HTMLElement) => {
  const lottieInstance = cache.get(element);
  if (lottieInstance) {
    lottieInstance.destroy();
  }
};

export const init = () => {
  getLottieElements().forEach((element) => {
    // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
    const hasIx2 = parseFloat(element.getAttribute('data-is-ix2-target')) === 1;

    if (!hasIx2) {
      cleanupElement(element);
    }

    createInstance(element);
  });
};

export const destroy = () => {
  getLottieElements().forEach(cleanupElement);
};

export const ready = init;
